import { createContext } from 'react'

import { ConnectionType } from './type'
import { WebSocketClient } from './websocketService'

export type WebsocketServiceContext = { ws: WebSocketClient | null; wsState: ConnectionType }
const websocketServiceContext = createContext<WebsocketServiceContext>({
  ws: null,
  wsState: ConnectionType.CLOSED,
})

export default websocketServiceContext
